import React from 'react';
import ReactPlayer from 'react-player/youtube';
import firebase from '../../firebase';
import LazyLoad from 'react-lazyload';
const storageRef = firebase.storage().ref('images/');

/**
 * @function ContentGrid
 * @returns { Component } - ContentTile
 */
const VideoTile = (model) => {
    return (
        <div className="content-tile__container">
            { 
                model.data && model.data.components &&
                model.data.components.map(function(item) {
                    if (item.isImage) {
                        storageRef.child(item.url).getDownloadURL().then(function(path) {
                            document.getElementById(item.id).src = path;
                        });
                        return (
                            <div key={item.id}>
                                <span className="video-tile__title" style={{maxWidth: "600px", margin: "auto", display: "block"}}> {item.name} </span>
                                <div className="img-overlay-wrap" style={{boxShadow: "5px 10px 23px 5px rgba(0,0,0,0.41)", maxWidth: "600px", margin: "auto", display: "block"}}>
                                    <LazyLoad>
                                        <img id={item.id} className="video-tile__image"></img>
                                    </LazyLoad>
                                </div>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div key={item.id} style={{boxShadow: "5px 10px 23px 5px rgba(0,0,0,0.41)", maxWidth: "700px", margin: "auto"}}>
                                <ReactPlayer
                                    className="content-tile__video"
                                    url={item.url}
                                    key={item.id}
                                />
                            </div>
                        );
                    }
                })
            }
        </div>
    );
}

export default VideoTile;