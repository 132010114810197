import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import imageSource from '../../assets/profile.png';

/**
 * @function NavHeader
 * @returns {Component} - NavHeader
 */
function NavHeader(model) {
    return (
        <div className="nav-header__container">
            <Navbar className="ml-auto">
                <Navbar.Brand>
                    <img
                        className="navbar__image"
                        src={imageSource}
                        alt="B41492 logo"
                    />
                </Navbar.Brand>
                
                {/* <Navbar.Collapse>
                    <div className="navbar__tab-container">
                        {
                            model.data.tabs.map((tab => {
                                return (
                                    <Nav key={tab.id} className="justify-content-end navbar__tab-item">
                                        <Nav.Link href={tab.url}>{tab.text}</Nav.Link>
                                    </Nav>
                                );
                            }))
                        }
                    </div>
                </Navbar.Collapse> */}
            </Navbar>
        </div>
    );
}

export default NavHeader;