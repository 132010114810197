import Data from './assets/prod.json';


export const getModelData = (component) => {
    return Data.data[component];
};

/**
 * @function detectDevice - detect if current device is mobile
 * @param { window } - window object
 * @returns { bool } - isMobile
*/
export const mediaQuery = () => {
    const width = window.innerWidth;
    const breakpoint = 620;
    return width < breakpoint ? true : false;
}

/**
 * @function newlineText - parse text newlines in html
 * @param {*} text 
 */
export const newlineText = (text) => {
    return text.split('\n').map(str => <p className="header__text">{str}</p>);
}

export const dataUtility = (section) => {
    return Data.data[section];
}