import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDIcr4-e-CwypgYg4bv7C_JxR1MEYR56K4",
    authDomain: "b41492-c3102.firebaseapp.com",
    projectId: "b41492-c3102",
    storageBucket: "b41492-c3102.appspot.com",
    messagingSenderId: "293977099029",
    appId: "1:293977099029:web:39058f8df1952c20bab71c"
};

firebase.initializeApp(firebaseConfig);

export default firebase;