import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Advisory from './components/Advisory/Advisory';
import ContentTile from './components/ContentTile/ContentTile';
import Footer from './components/Footer/Footer';
import { getModelData } from './utility'

function App() {
	// Model Values
	const navbarModel = getModelData('navbar');
	const advisoryModel = getModelData('advisory');
	const contentModel = getModelData('content-body');


  	return (
    	<div className="App">
			<Navbar data={navbarModel} />
			<div className="content">
				{/* <Advisory data={advisoryModel} /> */}
				<ContentTile data={contentModel}/>
				<Footer/>
			</div>
    	</div>
  	);
}

export default App;
