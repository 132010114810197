import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

/**
 * @function NavHeader
 * @returns {Component} - NavHeader
 */
const Footer = (model) => {
    return (
        <div className="footer__container">
            <Navbar expand="lg" sticky="bottom" style={{height: "80px"}}>
                <Nav>
                    <span className="footer__item" style={{float: "left"}}><strong>Contact Us</strong> | b41492@gmail.com </span>
                    {/* <span className="footer__item"style={{float: "right"}}> Copyright 2021 <sup>TM</sup></span> */}
                </Nav>
            </Navbar>
        </div>
    );
}

export default Footer;